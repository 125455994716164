import React from 'react'
import styled from "styled-components"
import {useStaticQuery, graphql, Link} from "gatsby"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import PropertyConverter from './PropertyConverter';
import ContractedMark from './ContractedMark';

const RecommendPropertyArea = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`
const RecommendPropertyTitle = styled.h2`
    ${pc`
    font-size: 24px;
    `}
    ${sp`
    font-size: 22px;
    `}
    font-weight: bold;
    padding: 10px 0px;
    margin: 20px 0px;
    border-bottom: solid 1px #cbd0d5;
`
const RecommendPropertyCell = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    ${sp`
        justify-content: space-evenly
    `}
    ${pc`
        justify-content: center;
    `}
`
const RecommendPropertyItem = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    // padding: 10px;
    // height: 60px;
    border-radius: 3px;
    border: solid 1px #cbd0d5;
`
const RecommendPropertyDetail = styled.p`
    ${pc`
    &:first-of-type{
        padding: 10px 10px 3px 10px;
    }
    &:last-of-type{
        padding: 3px 10px 10px 10px;
    }
    `}
    ${sp`
    &:first-of-type{
        padding: 5px 5px 3px 5px;
    }
    &:last-of-type{
        padding: 3px 5px 5px 5px;
    }
    font-size: 12px;
    `}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 10px 3px 10px;
    max-width: 100%;
`

const PropertyStyle = {  // ::before属性は css に記述
    "width": "calc((100% - 10px * 2 - 10px * 2) / 4)",
    "height": "280px",
    "padding": "5px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "background": "white",
    "flex-direction": "column",
    // "box-shadow": "0px 0px 15px 0px #777777",
    "border-radius": "3px",
    "border": "1"
}

const PropertySpStyle = {  // ::before属性は css に記述
    "width": "calc((100% - 10px * 3) / 2)",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-wrap": "wrap",
    "background": "white",
    "flex-direction": "column",
    "border-radius": "3px",
    "border": "1",
}

const GatsbyImgPcStyle = {  // ::before属性は css に記述
    "height": "165px",
}

const GatsbyImgSpStyle = {  // ::before属性は css に記述
    "height": "165px",
    "border-bottom": "solid 1px #33333333",
}


export default function RecommendProperty() {
    const data = useStaticQuery(graphql`
    query ListRecommendPropertiesQuery {
        Data {
            listPropertiesSortByUpdatedAt(
                filter: {isRecommendedProperty: {eq: true}, _deleted: {ne: true}}
                sortDirection: DESC
                type: PROPERTY
                limit: 15
            ) {
                items {
                    id
                    title
                    rent
                    buildingAreaTotal
                    image01
                    remarks
                    iscontracted
                    pathPropertyImages{
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                        }
                    }
                }
            }
        }
    }
    `)

    const isPc = useMedia({minWidth: '560px'});
    const recommendPropertyItems = data?.Data?.listPropertiesSortByUpdatedAt?.items.slice(0, 4) || []

    const width_ = isPc ? "100%" : "100%";
    const height_ = isPc ? "165px" : "165px";
    const fontSize_ = isPc ? "32px" : "20px";

    return (
        <RecommendPropertyArea>
            <RecommendPropertyTitle>お薦め物件</RecommendPropertyTitle>
            <RecommendPropertyCell>
                {
                    recommendPropertyItems.map((item) => {
                        let path = "/property/" + item.id;
                        const Thumbnail = getImage(item.pathPropertyImages[0])
                        const convertedItem = new PropertyConverter(item).convert()
                        return (
                            <a 
                                href={path} 
                                style={isPc ? PropertyStyle : PropertySpStyle} 
                                className="SquareCardStyle"
                                key={convertedItem.id}
                                target="_blank"
                            >
                                <RecommendPropertyItem key={convertedItem.id}>
                                    {item.iscontracted ? <ContractedMark width={width_} height={height_} fontSize={fontSize_}/> : null}
                                    <GatsbyImage className={convertedItem.title} image={Thumbnail} style={isPc ? GatsbyImgPcStyle : GatsbyImgSpStyle} />
                                    <RecommendPropertyDetail>{convertedItem.title}</RecommendPropertyDetail>
                                    <RecommendPropertyDetail>{convertedItem.rent} 円</RecommendPropertyDetail>
                                    <RecommendPropertyDetail>{convertedItem.buildingAreaTotal}㎡/{convertedItem.buildingAreaTotalTsubo}坪</RecommendPropertyDetail>
                                    <RecommendPropertyDetail>{convertedItem.remarks}</RecommendPropertyDetail>
                                </RecommendPropertyItem>            
                            </a>
                        )
                    })
                }
        </RecommendPropertyCell>
      </RecommendPropertyArea>
    )
}
