import React from 'react'
import Layout from "../components/Layout"
import RecommendProperty from "../components/RecommendProperty"
import BlogSection from "../components/BlogSection"
import SearchPropertySection from "../components/SearchPropertySection"
// import TopTabSection from '../components/TopTabSection'
import { Helmet } from "react-helmet"


export default function index({ data }) {
  return (
    <Layout layoutType="Top">
      <Helmet>
            <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地情報</title>
            <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
      </Helmet>

      <SearchPropertySection layoutType="Top"/>
      {/* <TopTabSection /> */}
      <hr />
      <RecommendProperty />
      <hr />
      <BlogSection />
    </Layout>
  )
}

