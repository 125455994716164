import React from 'react'
import styled from "styled-components"
import {useStaticQuery, graphql, Link} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';

const BlogArea = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`
const BlogTitle = styled.h2`
    ${pc`
    font-size: 24px;
    `}
    ${sp`
    font-size: 22px;
    `}
    font-weight: bold;
    padding: 10px 0px;
    margin: 20px 0px;
    border-bottom: solid 1px #cbd0d5;
`
const BlogCell = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    justify-content: center;
`
const BlogItem = styled.div`
    width: 100%;
    height: auto;
    border: solid 1px #cbd0d5;
    border-radius: 3px;
`
const BlogItemDetailTitle = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${sp`
        padding: 5px 5px 2px 5px;
        font-size: 12px;
    `}
    ${pc`
        padding: 10px 10px 5px 10px;
    `}
`
const BlogItemDetailCaption = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${sp`
        padding: 0px 5px 5px 5px;
        font-size: 12px;
    `}
    ${pc`
        padding: 0px 10px 10px 10px;
    `}
`
const BlogStyle = {  // ::before属性は css に記述
    "width": "calc((100% - 10px * 2 - 10px * 2) / 4)",
    "height": "auto",
    "padding": "5px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "background": "white",
    "border-radius": "3px",
}
const BlogSpStyle = {  // ::before属性は css に記述
    "width": "calc((100% - 10px * 3) / 2)",
    "height": "auto",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "background": "white",
    "border-radius": "3px",
}

const GatsbyImagePcStyle = {
    "height": "170px"
}

export default function BlogSection() {
    const BlogData = useStaticQuery(graphql`
    query ListBlogsQuery {
        Data {
            latestBlog: listBlogsSortByUpdatedAt(
            filter: {_deleted: {ne: true}}
            limit: 1
            sortDirection: DESC
            type: BLOG
            ) {
                items {
                    title01
                    title02
                    title03
                    body01
                    body02
                    body03
                    image01
                    image02
                    image03
                    createdAt
                    id
                    pathBlogImages{
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                        }      
                    }
                }
            }
        }
        Data {
            recommendedBlog: listBlogsSortByUpdatedAt(
                filter: {isRecommendedBlog: {eq: true}, _deleted: {ne: true}}
                limit: 8
                sortDirection: DESC
                type: BLOG
                ) {
                    items {
                        title01
                        title02
                        title03
                        body01
                        body02
                        body03
                        image01
                        image02
                        image03
                        createdAt
                        id
                        pathBlogImages{
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                        }      
                    }
                }
            }
        }
    }
    `)

    console.log("BlogData: ", BlogData)
    const isPc = useMedia({minWidth: '560px'});
    const LatestBlogItems = BlogData?.Data?.latestBlog?.items || []
    let RecommendedBlogItems = BlogData?.Data?.recommendedBlog?.items || []
    RecommendedBlogItems = RecommendedBlogItems.filter((item) => {
        return item.id !== LatestBlogItems[0].id
    }).slice(0, 3)
    return (
        <BlogArea>
            <BlogTitle>特集</BlogTitle>
            <BlogCell>
                {
                    RecommendedBlogItems.map((item) => {
                        if(item.id!==LatestBlogItems[0].id){
                            let path = "/blog/" + item.id;
                            const Thumbnail = getImage(item.pathBlogImages[0])

                            return (
                                <Link
                                    to={path}
                                    style={isPc ? BlogStyle : BlogSpStyle}
                                    className="SquareCardStyle"
                                >
                                    <BlogItem key={item.id}>
                                        <GatsbyImage style={GatsbyImagePcStyle} className={item.title01} image={Thumbnail} />
                                        <BlogItemDetailTitle>{item.title01}</BlogItemDetailTitle>
                                        <BlogItemDetailCaption>{item.body01}</BlogItemDetailCaption>
                                    </BlogItem>            
                                </Link>
                            )
                        }
                    })
                }
                {
                    LatestBlogItems.map((item) => {
                        let path = "/blog/" + item.id;
                        const Thumbnail = getImage(item.pathBlogImages[0])

                        return (
                            <Link
                                to={path}
                                style={isPc ? BlogStyle : BlogSpStyle}
                                className="SquareCardStyle"
                            >
                                <BlogItem key={item.id}>
                                    <GatsbyImage style={GatsbyImagePcStyle} className={item.title01} image={Thumbnail} />
                                    <BlogItemDetailTitle>{item.title01}</BlogItemDetailTitle>
                                    <BlogItemDetailCaption>{item.body01}</BlogItemDetailCaption>
                                </BlogItem>            
                            </Link>
                        )
                    })                    
                }
            </BlogCell>
        </BlogArea>
    )
}
