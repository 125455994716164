class PropertyConverter{

    
    constructor(propertyObj) {
        this.propertyObj = propertyObj
        this.changedObj = {}
        this.changedObj.buildingAreaEachTsubo = []
        this.exchangeMap = {
            buildingAreaTotal: this.separatedComma,
            // buildingAreaEach: this.separatedComma,
            rent: this.separatedComma
        }
    }

    convert = () => {
        for(const k in this.propertyObj){
            if(k in this.exchangeMap){
                this.changedObj[k] = this.exchangeMap[k](this.propertyObj[k])
            }else{
                this.changedObj[k] = this.propertyObj[k]
            }
        }
        this.changedObj.buildingAreaTotalTsubo = this.convertTsubo(this.propertyObj.buildingAreaTotal)
        
        if(this.propertyObj.buildingAreaEach instanceof Array){
            for(const area in this.propertyObj.buildingAreaEach){
                this.changedObj.buildingAreaEachTsubo.push(this.convertTsubo(this.propertyObj.buildingAreaTotal))
            }
        }else{
            this.changedObj.buildingAreaEachTsubo = null
        }
        return this.changedObj
    }

    _isNumber = (value) => {
        return Number(value)===value
    }

    separatedComma = (value) => {
        if(this._isNumber(value)){
            var s = String(value).split('.');
            var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (s.length > 1) {
                ret += '.' + s[1];
            }
            return ret;
        }
        else if(value instanceof Array){
            let newArr = []
            for(let i in value){
                newArr[i] = this.separatedComma(value[i])
            }
            return newArr
        }
    }

    convertTsubo = (value) => {
        // 坪数は 0.3025 倍で計算, 小数点第２位以下を切り捨て
        return this.separatedComma(Math.floor(value * 0.3025 * 100) / 100);
    }
}

export default PropertyConverter