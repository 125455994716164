import React from 'react'
import styled from "styled-components"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';
import {StaticImage} from "gatsby-plugin-image"


export default function ContractedMark({width, height, fontSize}) {
    const isPc = useMedia({minWidth: '560px'});
    
    const ContractedMarkArea = styled.div`
        position: absolute;
        z-index: 90;
        // mix-blend-mode: multiply;
        width: ${width};
        height: ${height};
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.8;
        ${pc`
        `}
    `
    
    const ContractedMarkItem = styled.p`
        ${pc`
        font-size: ${fontSize};
        // padding: 24px;
        // padding: 1vw;
        padding: 5%;
        `}
        ${sp`
        font-size: ${fontSize};
        // padding: 10px;
        padding: 2.5vw;
        `}
        border: solid 1px rgb(89,201,171);
        border-radius: 3px;
        font-weight: bold;
        background: rgb(89,201,171);
        color: white;
        letter-spacing: .08em;
    `
    
    return (
        <ContractedMarkArea>
            <ContractedMarkItem>成約済</ContractedMarkItem>
        {/* <StaticImage
            src="../images/contracted.png"
            alt="契約済みスタンプ"
            placeholder="blurred"
            layout="fixed"
            width={100}
            height={100}
        /> */}
        </ContractedMarkArea>
    )

}